import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL, MAX_COLS } from "./../../global";



export const getCustomColumnCount = (
  tableId,
  certificateStaticTables,
  staticTables,
  isStatic = null,
  isCertificate = null,
  tables = null
) => {
  let tbl = tables;
  if (!tbl) {
    tbl =
      isStatic === "static"
        ? isCertificate === "certificate"
          ? certificateStaticTables
          : staticTables
        : null;
  }

  let count = 0;
  if (!tbl) return count;
  for (let i = 0; i < tbl.length; i++) {
    if (tbl[i].id == tableId) {
      for (let j = 0; j < MAX_COLS; j++) {
        if (tbl[i]["c" + (j + 1)] != null) {
          count++;
        } else {
          break;
        }
      }
    }
  }
  return count;
};

export const clearPreviousReadings = async (table, column, id) => {
  let status = false;
  // 3. delete records with respective datasheet/table id from given table
  try {
    const insertQueryPayload = {
      query: `delete from ${table} where ${column} = ${id}`,
    };
    let res = await axiosWithToken.post(`${BASE_URL}dynamic`, insertQueryPayload);
    if(res.status == 200){
      status = true
    }
  } catch (error) {
    console.error(
      `[ERRRO] Failed while clearing previous readings, error: ${error}`
    );
    status = false;
  }
  return status;
};