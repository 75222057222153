import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  useMediaQuery,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tables from "../../utils/components/tables";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExcelUpload from "../../utils/components/excelUpload";
import { allSettings } from "../../utils/components/allSettings";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";
import ErrorModal from "../../utils/components/errorModal";
import { ducMasterListGuide } from "../master/ducMaster/guide";
import { getFormattedDates } from "../../utils/utils";


var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "entryDate",
    label: "Date Of SRF",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificatesserviceReqNumber",
    label: "SRF Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "clientName",
    label: "Customer Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "totalJobNo",
    label: "Total Jobes",
    align: "left",
    minWidth: 40,
  },
  {
    id: "jobNumber",
    label: "Job Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ULRNo",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let notNeededColumn = ["id","instrumentId","clientId","lastModified"];

const SrfUlrRegisterList = () => {
  const ducMasterListGuides = ducMasterListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "SRF Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [finalData, setNewFinalData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
	  

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "SRF Number": "certificatesserviceReqNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
        const { fromDate, toDate } = getFormattedDates(from, to);
        if (whereConditions === "") {
          whereConditions = " where ";
        } else {
          whereConditions += " and ";
        }
  
        whereConditions += ` cv.entryDate between '${fromDate}' and '${toDate}'`;
      }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cv.ULRNo, cv.certificateNumber, cv.certificatesserviceReqNumber, cv.instrumentName, cv.name, cv.jobNumber, cv.entryDate, cv.clientId, c.companyName AS clientName FROM certificatesView cv LEFT JOIN clients c ON cv.clientId = c.id ${whereConditions}  order by cv.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows  FROM certificatesView cv LEFT JOIN clients c ON cv.clientId = c.id  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  function fetchFinalData() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchFinalData" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname); 
    setSearched(true);
    fetchFinalData();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("SRF Number");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    const confirmed = window.confirm("Really want to delete DUC?");
    if (!confirmed) {
      return;
    }
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `ducMaster/${id}`)
      .then((res) => {
        toast("ducMaster is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "handleDelete" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const copyDUC = async (id) => {
    const confirmed = window.confirm(
      "Do want to copy and create new DUC using selected DUC?"
    );
    if (!confirmed) {
      return;
    }

    let ducData = await axiosWithToken
      .get(BASE_URL + `ducMaster/${id}`)
      .then((res) => res.data[0] || []);
    delete ducData.id;
    delete ducData.lastModified;

    axiosWithToken.post(BASE_URL + "ducMaster", ducData).then((res) => {
      setTimeout(refresh, 500);
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
    fetchFinalData();
    }
    if (searched) {
      setPage(0);
    }
  }, [rowsPerPage,page, searched]);

  function groupByClientAndServiceReq(data) {
    return data.reduce((acc, curr) => {
      const clientName = curr.clientName;
      const certServiceReqNo = curr.certificatesserviceReqNumber;
      const entryDate = new Date(curr.entryDate).toISOString().split('T')[0]; // Group by date only (without time)
  
      // Create a unique key combining `clientName`, `certificatesserviceReqNumber`, and `entryDate`
      const key = `${clientName}-${certServiceReqNo}-${entryDate}`;
  
      // If the group does not exist, create it
      if (!acc[key]) {
        acc[key] = {
          entryDate: entryDate,
          certificatesserviceReqNumber: certServiceReqNo,
          clientName: clientName,
          jobNumber: [curr.jobNumber],
          certificateNumber: [curr.certificateNumber],
          ULRNo: [curr.ULRNo]

        };
      } else {
        // Push the `jobNumber`, `certificateNumber`, and `ULRNo` to the arrays
        acc[key].jobNumber.push(curr.jobNumber);
        acc[key].certificateNumber.push(curr.certificateNumber);
        acc[key].ULRNo.push(curr.ULRNo);
      }
  
      return acc;
    }, {});
  }
  
  // Function to format the last and first value with "To" in between
  function formatFirstAndLast(arr) {
    if (arr.length > 1) {
      return `${arr[arr.length - 1]} To ${arr[0]}`;
    }
    return arr[0]; // Return single value if only one element
  }
  
  // Function to finalize the grouped data with formatted last and first values
  function finalizeGroupedData(groupedData) {
    return Object.keys(groupedData).map(key => {
      const group = groupedData[key];
      return {
        entryDate: group.entryDate,
        certificatesserviceReqNumber: group.certificatesserviceReqNumber,
        clientName: group.clientName,
        totalJobNo: group?.jobNumber?.length,
        jobNumber: formatFirstAndLast(group.jobNumber),
        certificateNumber: formatFirstAndLast(group.certificateNumber),
        ULRNo: formatFirstAndLast(group.ULRNo),
      };
    });
  }
  
 
  
useEffect(()=>{
 // Call the function
 const groupedData = groupByClientAndServiceReq(data);
 const finalData = finalizeGroupedData(groupedData);
 setNewFinalData(finalData)
},[data])



  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
           <Grid
          item
          xs={6} sm={3} md={1} lg={1.5}
          id="certificate-certificatelistdatefrom"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              className="Listinputcss"
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6} sm={3} md={1} lg={1.5}
          id="certificate-certificatelistdateto"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              className="Listinputcss"
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} id="ducMaster_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          value={searchBy}
          options={
            _userType != 3
              ? [
                  { key: "certificatesserviceReqNumber", label: "SRF Number" },
                ]
              : [{ key: "certificatesserviceReqNumber", label: "SRF Number" }]
          }
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField
         className="Listinputcss"
          id="ducMaster_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          value={searchKey}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={6} sm={2} md={1} lg={0.5} textAlign={"left"}>
        <Button
          id="ducMaster_list_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    
      {!isMobile && (
        <Grid item xs={6} sm={3} md={4} lg={2}>
          <ExcelDownload finalData={finalData} notNeededColumn={notNeededColumn} />
        </Grid>
      )}
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
      {filterModal()}
      {!isMobile && filterSection()}
      <Tables
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleDelete={handleDelete}
        columns={columns}
        finalData={finalData}
        // actions={actions}
        totalRows={finalData?.length}
        // isPrint={true}
      />
        {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </ListWrap>
  );
};

export default SrfUlrRegisterList;
