import { getCustomColumnCount } from "../../helper";
import processDatasheetTemplate from "../processDatasheetTemplate";
import { prepareDatasheetReadings } from "../utils";

const checkMasters = (
  staticReadingRows
) => {
  if (
    staticReadingRows
      .map((row) => {
        if (
          !String(row['c1'])?.includes("_unit_") &&
          !String(row['c1'])?.includes("_rh_")
        ) {
          let masters = row['standardRanges'];
          masters =
            masters !== undefined && masters !== null && masters !== ""
              ? `${masters}`
                .split(",")
                .map((e) => e.trim())
                .filter((e) => e.length > 0)
              : [];
          if (masters.length === 0) return true;
        }
        return false;
      })
      .filter((e) => e).length
  ) {
    if (
      window.confirm("Masters is/are not added for rows? Do you want to save?")
    )
      return true;
    return false;
  }
  return true;
};

export async function processDatasheet(
  staticTables,
  staticReadingRows,
  datasheetObservedReadingFallbackValue,
  certificateStaticTables,
  datasheetDetails,
  standardRanges,
  selectedStandardMasters,
  referenceData,
) {
  try {
    console.log("validating datasheet readings");
    if (
      !checkMasters(staticReadingRows)
    ) {
      return false;
    }
    console.log("processing datasheet readings");
    // prepare readings
    let config = staticTables.map((table, tableIndex) => {
      return {
        tableId: table.id,
        index: tableIndex,
        formulas: table?.defaultConfiguration?.formulas,
        customPrecision: JSON.parse(table.customPrecision),
      };
    });

    let updatedReadingRows = await prepareDatasheetReadings({
      readings: staticReadingRows,
      datasheetObservedReadingFallbackValue: datasheetObservedReadingFallbackValue,
      config: config,
      datasheet: datasheetDetails,
      standardRanges: standardRanges,
      selectedStandardMasters: selectedStandardMasters,
      referenceData: referenceData,
      operations: [""],
    });
    updatedReadingRows = await processDatasheetTemplate(
      updatedReadingRows,
      datasheetDetails?.instrumentId,
      staticTables,
      datasheetDetails?.id,
      datasheetDetails
    );

    // TODO: this process is getting called twice, which shouldn't. really need to optimize
    updatedReadingRows = prepareDatasheetReadings({
      readings: updatedReadingRows,
      datasheetObservedReadingFallbackValue: datasheetObservedReadingFallbackValue,
      config: config,
      datasheet: datasheetDetails,
      standardRanges: standardRanges,
      referenceData: referenceData,
      operations: ["setPrecision"],
    });

    console.log("datasheet readings process completed.");
    return updatedReadingRows;

  } catch (error) {
    console.error("[ERROR] Error occured while processing datasheet readings, error: ", error);
    alert("Failed while processing datasheet data, please try again.");

    return false;
  }
}
