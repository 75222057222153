import { useEffect, useState } from "react";
import "./notification.css";
import { BASE_URL } from "../../global";
import { IconButton, Menu } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SyncIcon from "@mui/icons-material/Sync";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

// notification: {
//     _from: user[0],
//     _to: user[0],
//     body: rejectionMessage,
//     type: 0/1 Optional :> default 0 :> 0: View pnly, 1: Reply,
//   };
// postAction: function to run after notification is created
export const notifiy = (notification, postAction) => {
  let query = "/notification";
  if (notification?.length) {
    notification.map(
      (_, i) => (notification[i].time = moment().format("YYYY-MM-DD HH:mm:ss"))
    );
    query = "/notification/bulk";
  } else notification.time = moment().format("YYYY-MM-DD HH:mm:ss");
  return axiosWithToken
    .post(BASE_URL + query, notification)
    .then((res) => {
      return postAction(notification);
    })
    .catch((err) => {
      console.log("error while creating notifications!");
      return;
    });
};

const NotificationCell = (props) => {
  const [replyText, setReplayText] = useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  // console.log("replyText : ", replyText, "(", props.id, ")");

  return (
    <div className="notification-cell">
      <div className="notification-cell-content">
        {props.notification && (
          <div className="notification-info">
            <div className="notification-info-from">
              From:{props.notification.userName}
            </div>
            <div></div>
            <div className="notification-info-time">
              At:
              {props.notification.time &&
              moment(props.notification.time).format("MM/DD/YYYY HH:mm:ss") !==
                "Invalid date"
                ? moment(props.notification.time).format("MM/DD/YYYY HH:mm:ss")
                : "-"}
            </div>
          </div>
        )}
        <div
          className="notification-cell-content-text"
          style={
            props.open && props.notification?.type == 1
              ? { height: "0px !important" }
              : {}
          }
        >
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {props.notification
              ? props.notification?.body
              : "Wating For Notifications..."}
          </pre>
        </div>
        {props.notification && (
          <div
            className={`notification-cell-reply-container ${
              props.open ? "open-reply" : "close-reply"
            }`}
          >
            <form>
              <textarea
                className={`notification-cell-reply`}
                onClick={(e) => e.stopPropagation()}
                value={replyText}
                onChange={(e) => {
                  e.stopPropagation();
                  setReplayText(e.target.value);
                }}
              >
                {/* {replyText} */}
              </textarea>
            </form>
          </div>
        )}
      </div>
      {props.notification && (
        <div className="notification-cell-action ">
          <div className={`reply-button-container`}>
            {props.notification?.type === 0 ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  props.removeNotification(props.notification);
                }}
                edge="start"
                size="small"
                sx={{ ml: 2 }}
                style={{
                  backgroundColor: "white",
                  margin: "5px",
                  color: "red",
                }}
              >
                <DeleteSweepIcon />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  if (props.open) props.setOpenReply(-1);
                  else props.setOpenReply(props.id);
                }}
                edge="start"
                size="small"
                sx={{ ml: 2 }}
                style={{
                  backgroundColor: "white",
                  margin: "5px",
                }}
              >
                {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </div>
          <div style={{ flex: "auto" }}></div>
          <div
            className={`reply-button-container reply ${
              props.open ? "rotate-appear" : "rotate-disappear"
            }`}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setReplayText("");
                const notification = {
                  _from: user[0],
                  _to: props.notification?._from,
                  body: replyText,
                  type: 0,
                  id: props.notification?.id,
                };
                setTimeout(() => props.reply(notification, props.id), 100);
              }}
              edge="start"
              size="small"
              sx={{
                ml: 2,
                pointerEvents: replyText.length === 0 ? "none" : "",
                cursor: replyText.length === 0 ? "not-allowed" : "",
                opacity: replyText.length === 0 ? 0.5 : 1,
              }}
              style={{
                backgroundColor: "white",
                margin: "5px",
              }}
            >
              <ReplyIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export const NotificationList = ({
  anchorEl,
  open,
  close,
  notifyNumber,
  isMobile,
}) => {
  const [openReply, setOpenReply] = useState(-1);
  const [notifications, setNotifications] = useState([]);
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  const removeNotification = async (n, id) => {
    await axiosWithToken.delete(BASE_URL + `notification/${n?.id}`);
    fn();
  };

  const reply = (n, id) => {
    // setOpenReply(-1);
    let tn = { ...n };
    delete tn.id;
    notifiy(tn, () => {
      removeNotification(n, id);
    });
  };

  const fn = async () => {
    let tNotification = [];
    await axiosWithToken
      .post(BASE_URL + `dynamic`, {
        query: `select n.*,u.userName from notification as n left join users as u on n._from = u.id where n.status != -1 and n._to = ${user[0]} order by id desc`,
      }, {headers: {requestType: 'fetchData'}})
      .then((res) => tNotification.push(res.data));
    tNotification = [...tNotification.flat()];
    notifyNumber(tNotification.length || 0);
    setNotifications(tNotification);
  };

  useEffect(() => {
    fn();
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      disableAutoFocus
      id="rejected-certificates"
      onClose={close}
      open={open}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      style={{ maxHeight: "750px", overflow: "hidden" }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: "-50px",
          width: "50px",
          height: "50px",
          backgroundColor: "#fff0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...(isMobile && { top: "-5px", left: "-15px" }),
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            fn();
          }}
          edge="start"
          size="small"
          style={{ color: "#000", border: "2px solid #000" }}
          className="notification-refresh-button"
        >
          <SyncIcon />
        </IconButton>
      </div>
      <div className="notification-container">
        {(notifications?.length ? notifications : [false]).map((rC, id) => (
          <NotificationCell
            key={rC?.id || id}
            id={id}
            notification={rC}
            setOpenReply={setOpenReply}
            open={openReply === id && rC.type === 1}
            removeNotification={removeNotification}
            reply={reply}
          />
        ))}
      </div>
    </Menu>
  );
};

export const NotificationBoard = () => {};
