import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Preview as PreviewIcon,
  Email as EmailIcon,
  SendTimeExtension as SendTimeExtensionIcon,
  Description as DescriptionIcon,
  Print as PrintIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  MoreVert as MoreVertIcon,
  Leaderboard as LeaderboardIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const SrfActionButtons = ({
  row,
  isCSRF,
  editAccess,
  showComponent,
  handleOpen,
  SetSelectedColumn,
  submitSiteDelete,
  srfApproved,
  handlePrintModalOpen,
  handleOpenTimeline,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <TableCell>
      <ButtonGroup size="small" aria-label="small button group">
        <Tooltip title="Edit SRF" placement="top-start">
          <Button
            id="srflist-editsrf"
            component={Link}
            to={`/inward/editInward/${row.id}`}
          >
            <PreviewIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Print Srf" placement="top-start">
          {/* {isCSRF ? (
            <Button onClick={() => handlePrintModalOpen(row.id)}>
              <PrintIcon />
            </Button>
          ) : ( */}
            <Button
              id="srflist-printsrf"
              component={Link}
              to={`/srf/printCsrf/${row.id}`}
            >
              <PrintIcon />
            </Button>
          {/* )} */}
        </Tooltip>

        {showComponent("delete") && (
          <Tooltip title="Delete Srf" placement="top-start">
            <Button
              id="srflist-deletesrf"
              disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
              onClick={(e) => {
                if (window.confirm("Really want to delete srfs?")) {
                  submitSiteDelete(row.id);
                }
              }}
            >
              <DeleteIcon
                style={{
                  color: !(editAccess?.includes(2) || editAccess?.includes(0))
                    ? "lightgray"
                    : "red",
                }}
              />
            </Button>
          </Tooltip>
        )}

        <Tooltip title="More Options" placement="top-start">
          <Button
            aria-controls={open ? "actions-menu" : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() =>
            handleOpenTimeline(row.id, row.customerName, row.serviceReqNumber)
          }
        >
          <Tooltip title="Progress List" placement="top-start">
            <Button style={{ padding: "2px 0px" }}>
              <LeaderboardIcon /> &nbsp;&nbsp; Progress
            </Button>
          </Tooltip>
        </MenuItem>

        <MenuItem
          onClick={() => {
            SetSelectedColumn(row);
            handleOpen();
          }}
        >
          <Tooltip title="Send Email" placement="top-start">
            <Button id="srflist-srfmail" style={{ padding: "2px 0px" }}>
              <EmailIcon /> &nbsp; &nbsp; Mail
            </Button>
          </Tooltip>
        </MenuItem>

        <MenuItem>
          <Tooltip title="Dispatch" placement="top-start">
            <Button
              id="srflist-srfdispatch"
              component={Link}
              to={`/srf/dispatch/${row.id}/${row.clientId}`}
              style={{ padding: "2px 0px" }}
            >
              <SendTimeExtensionIcon /> &nbsp; &nbsp; Dispatch
            </Button>
          </Tooltip>
        </MenuItem>

        <MenuItem>
          <Tooltip title="Dispatch Status" placement="top-start">
            <Button
              id="srflist-srfdispatchstatus"
              component={Link}
              to={`/srf/DispatchStatus/${row.id}`}
              style={{ padding: "2px 0px" }}
            >
              <DescriptionIcon /> &nbsp; &nbsp; Dispatch Status
            </Button>
          </Tooltip>
        </MenuItem>

        <MenuItem>
          <Tooltip
            title="Approved Srf"
            placement="top-start"
            disabled={row.approvedby}
            onClick={(e) => srfApproved(row.id)}
            style={{ padding: "2px 0px" }}
          >
            <Button>
              <CheckCircleIcon />
              &nbsp; &nbsp; SRF Approved
            </Button>
          </Tooltip>
        </MenuItem>
      </Menu>
    </TableCell>
  );
};

export default SrfActionButtons;
