import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const GetSrfData = ({
  invoiceObject,
  updateQuoObject,
  setproductReadingRows,
  setGetAllSrfProducts,
}) => {
  // Function for making API calls
  const fetchDataFromAPI = async () => {
    const url = `${BASE_URL}dynamic`;
    const selectedAllSRN = invoiceObject?.selectedSRF;

    if (Array.isArray(selectedAllSRN) && selectedAllSRN.length > 0) {
      try {
        let selectedSRNs = selectedAllSRN
          .map((serviceReqNumber) => `'${serviceReqNumber}'`)
          .join(",");
        let SQlQuery = {
          query: `SELECT instrumentId FROM datasheets WHERE serviceReqNumber IN (${selectedSRNs})`,
        };
        const resultResponse = await axiosWithToken.post(url, SQlQuery);

        const instrumentIdsArray = resultResponse.data.map(
          (item) => item.instrumentId
        );

        if (instrumentIdsArray.length > 0) {
          const instrumentDetailsPayload = {
            query: `SELECT * FROM instrumentPriceList WHERE instrumentId IN (${instrumentIdsArray.join(
              ","
            )})`,
          };

          const instrumentDetailsResponse = await axiosWithToken.post(
            url,
            instrumentDetailsPayload
          );

          const clientID = invoiceObject?.companyName?.id;
          const filteredList = instrumentDetailsResponse?.data?.filter(
            (item) => item.clientId === clientID || item.clientId === 0
          );

          const instrumentDetails = Object.values(
            filteredList.reduce((map, item) => {
              if (!map[item.instrumentId] || item.clientId === clientID) {
                map[item.instrumentId] = item;
              }
              return map;
            }, {})
          );

          // const instrumentDetails = instrumentDetailsResponse.data;

          return { instrumentIdsArray, instrumentDetails };
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  };

  // Function for handling data
  const handleData = (data) => {
    if (data && data.instrumentIdsArray && data.instrumentDetails) {
      const { instrumentIdsArray, instrumentDetails } = data;

      const counts = {};
      instrumentIdsArray.forEach((id) => {
        counts[id] = (counts[id] || 0) + 1;
      });

      const templateObject = {
        equipmentId: null,
        description: null,
        hsnCode: null,
        service: "",
        rate: null,
        quantity: null,
        amount: null,
        type: null,
        referenceId: null,
        breakupId: null,
      };

      const updatedInstrumentDetails = instrumentDetails.map((detail) => {
        const { instrumentId, ...rest } = detail;
        const newDetail = { ...templateObject, ...rest };
        newDetail.equipmentId = detail.id || null;
        newDetail.quantity = counts[detail.instrumentId] || 0;
        return newDetail;
      });

      setGetAllSrfProducts(updatedInstrumentDetails);
      // setproductReadingRows(updatedInstrumentDetails)
    }
  };

  // Consolidated function utilizing the above functions
  const consolidatedFunction = async () => {
    try {
      const data = await fetchDataFromAPI();
      handleData(data);
    } catch (error) {
      console.error("Error in consolidatedFunction:", error);
    }
  };

  // Invoking consolidatedFunction in useEffect
  useEffect(() => {
    consolidatedFunction();
  }, [invoiceObject?.selectedSRF]);

  return <div></div>;
};

export default GetSrfData;
