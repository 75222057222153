import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const InfoModal = ({ open, handleClose, info, allUsers }) => {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.03900799053!2d72.88118615!3d19.082250749999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1722241809530!5m2!1sen!2sin";

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          "@media (max-width: 600px)": {
            width: "80%",
          },
          "@media (max-width: 480px)": {
            width: "90%",
          },
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{ color: "#001E6C", flexGrow: 1 }}
          >
            <b>Datasheet Info</b>
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{ minWidth: "0px" }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="p">
                <strong>Instrument Name:</strong> <br />
                &nbsp;&nbsp; {info?.instrumentName}
              </Typography>
              <br />
              <Typography variant="p">
                <strong>DUCID:</strong> <br />
                &nbsp;&nbsp; {info?.DUCID}
              </Typography>
              <br />
              <Typography variant="p">
                <strong>Last Modified Date:</strong> <br />
                &nbsp;&nbsp;{" "}
                {info?.lastModified
                  ? moment(info?.lastModified).format("DD-MM-YYYY hh:mm A")
                  : ""}
              </Typography>
              <br />
              <Typography variant="p">
                <strong>Calibrated By:</strong> <br />
                &nbsp;&nbsp;{" "}
                {allUsers?.find((user) => user.id == info.calibratedBy)
                  ?.userName || ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                height: 300, // Adjust the height for the map
                border: "1px solid #ccc",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <iframe
                src={mapUrl}
                width="100%"
                height="100%"
                style={{ border: 0, filter: "grayscale(100%) contrast(50%)" }} // Grayscale and low contrast for disabled effect
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: "bold",
                  pointerEvents: "none", // Allow clicks to pass through the overlay
                }}
              >
                Coming Soon
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InfoModal;
