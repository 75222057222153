export const mode = ["Source", "Measure"];

export const modalConfigs = [
  {
    buttonLabel: "Range",
    transformedStringLabel: "masterrange",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "masteraccuracy",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "masterleastcount",
  },
  {
    buttonLabel: "Uncertainty",
    transformedStringLabel: "masterUncertainty",
  },
];

export const etParameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
    "power",
    "AC High voltage",
    "DC High voltage",
    "Power factor", 
    "Active energy" ,
    "High resistance",
    "Gauss"    
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "AC Power @",
    "DC Power",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
    "power",
    "AC High voltage",
    "DC High voltage",
    "Power factor", 
    "Active energy" ,
    "High resistance",
    "Gauss" 
  ],
};

export const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
      "L-Type",
      "C-Type",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
      "L-Type",
      "C-Type",
    ],
  },
};

export const readingColumns = [
  // Columns for disciplineKey "0" (Electro-technical)
  [
    {
      field: "action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr.No.",
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
    },
    {
      field: "mode",
      headerName: "Mode",
    },
    {
      field: "etParameter",
      headerName: "ETParameter",
    },
    {
      field: "paratype",
      headerName: "Parameter Type",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
    },
    {
      field: "percentOfOutput",
      headerName: "% Of Output",
    },
    {
      field: "plusValue",
      headerName: "+ Value",
    },
    {
      field: "deviation",
      headerName: "Deviation",
    },
    {
      field: "reactionTime",
      headerName: "Master Temperature",
    },
    {
      field: "gravity",
      headerName: "Least Count",
    },
    {
      field: "drift",
      headerName: "Drift",
    },    
  ],
  // Columns for disciplineKey "1" (Others)
  [
    {
      field: "action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr.No.",
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
    },
    {
      field: "parameter",
      headerName: "Parameter",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
    },
    {
      field: "percentOfOutput",
      headerName: "% Of Output",
    },
    {
      field: "plusValue",
      headerName: "+ Value",
    },
    {
      field: "deviation",
      headerName: "Deviation",
    },
    {
      field: "reactionTime",
      headerName: "Master Temperature",
    },
    {
      field: "gravity",
      headerName: "Least Count",
    },
    {
      field: "drift",
      headerName: "Drift",
    },
  ],
  // Columns for disciplineKey "2" (mass & volume)
  [
    {
      field: "action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr.No.",
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
    },
    {
      field: "parameter",
      headerName: "Parameter",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
    },
    {
      field: "deviation",
      headerName: "Deviation",
    },
    {
      field: "reactionTime",
      headerName: "Master Temperature",
    },
    {
      field: "gravity",
      headerName: "Least Count",
    },
    {
      field: "drift",
      headerName: "Drift",
    },
    {
      field: "cmv",
      headerName: "Conventional Mass Value",
    },
    {
      field: "mpe",
      headerName: "MPE",
    },
    {
      field: "materialDensity",
      headerName: "Material density",
    },
  ],
  // Columns for disciplineKey "3" (thermal)
  [
    {
      field: "action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr.No.",
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
    },
    {
      field: "stability",
      headerName: "Stability",
    },
    {
      field: "emissivity",
      headerName: "Emissivity",
    },
    {
      field: "uniformity",
      headerName: "Uniformity",
    },
    {
      field: "axialUniformity",
      headerName: "AxialUniformity",
    },
    {
      field: "radialUniformity",
      headerName: "RadialUniformity",
    },
    {
      field: "deviation",
      headerName: "Deviation",
    },
    {
      field: "reactionTime",
      headerName: "Master Temperature",
    },
    {
      field: "gravity",
      headerName: "Least Count",
    },
    {
      field: "drift",
      headerName: "Drift",
    },
  ],
  // Columns for disciplineKey "4" (biomedical)
  [
    {
      field: "action",
      headerName: "Action",
    },
    {
      field: "id",
      headerName: "Sr.No.",
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
    },
    {
      field: "parameter",
      headerName: "Parameter",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
    },
    {
      field: "percentOfOutput",
      headerName: "% Of Output",
    },
    {
      field: "plusValue",
      headerName: "+ Value",
    },
    {
      field: "deviation",
      headerName: "Deviation",
    },
    {
      field: "reactionTime",
      headerName: "Master Temperature",
    },
    {
      field: "gravity",
      headerName: "Least Count",
    },
    {
      field: "drift",
      headerName: "Drift",
    },
  ],
];

export const parameters =[
  { label: "Contact", value: 1 },
  { label: "Non Contact", value: 2 },
  { label: "Tension Mode", "value": 3 },
  { label: "Compression Mode", "value": 4 },
  { label: "Pull", "value": 5 },
  { label: "Push", "value": 6 },
  { label: "Hanger", "value": 7 },
];

export const bioMedicalParameters = [
  { label: "ECG", value: 1 },
  { label: "SPO2", value: 2 },
  { label: "Pulse Rate", value: 3 },
  { label: "NIBP", value: 4 },
  { label: "RR", value: 5 },
  { label: "IBP", value: 6 },
  { label: "Temperature", value: 7 },
  { label: "Energy", value: 8 },
  { label: "Heart Rate", value: 9 },
  { label: "Pacer Rate", value: 10 },
  { label: "Pacer Amplitude", value: 11 },
  { label: "Tidal Volume", value: 12 },
  { label: "PEEP Pressure", value: 13 },
  { label: "Respiration Rate", value: 14 },
  { label: "TI/TE", value: 15 },
  { label: "O2 Concentration", value: 16 },
  { label: "Negative Pressure", value: 17 },
  { label: "Power", value: 18 },
  { label: "Flow", value: 19 },
  { label: "Conductivity", value: 20 },
  { label: "Temperature", value: 21 },
  { label: "Flow", value: 22 },
  { label: "Volume", value: 23 },
  { label: "Occlusion", value: 24 },
  { label: "Intensity", value: 25 },
  { label: "Irradiance", value: 26 },
  { label: "Earth Resistance", value: 27 },
  { label: "Insulation Resistance", value: 28 },
  { label: "Earth Leakage", value: 29 },
  { label: "Enclosure Leakage", value: 30 },
  { label: "Patient Leakage", value: 31 },
  { label: "FHR", value: 32 },
  { label: "Manometer", value: 33 },
];