import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import {
  TextField,
  Autocomplete,
  Table,
  Grid,
  useMediaQuery,
  Modal,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import EditIcon from '@mui/icons-material/Edit';
import ListWrap from "../commonComponent/listWrap";

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "supplierName",
    label: "Supplier Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "invoiceAmount",
    label: "Invoice Amount",
    align: "left",
    minWidth: 40,
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  supplierName,
  invoiceNumber,
  invoiceDate,
  invoiceAmount,
  invoiceFile,
  paymentStatus
) {
  return {
    id,
    supplierName,
    invoiceNumber,
    invoiceDate,
    invoiceAmount,
    invoiceFile,
    paymentStatus,
  };
}

export default function SupplierInvoiceList() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [supplierInvoicesList, setSupplierInvoicesList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || "Supplier Name"
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [totalRows, setTotalRows] = React.useState(0);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `supplierInvoices/${id}`)
      .then((res) => {
        toast("supplierInvoices was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => console.log(err));
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Supplier Name": "supplierName",
        "Invoice Number": "invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select id,supplierName,Address,Contact,gstNumber,invoiceDate,invoiceNumber,invoiceAmount,gstType,gstpercentages,paymentStatus,Remarks,invoiceFile,createdBy from supplierInvoices ${whereConditions} order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows from supplierInvoices ${whereConditions}`;
    return data;
  };

  function getSupplierList() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => setSupplierInvoicesList([...res.data]))
      .catch((err) =>
        console.log("supplierInvoices data fetching error: ", err)
      );
  }

  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => setTotalRows(res.data[0]?.no_of_rows))
      .catch((err) =>
        console.log("supplierInvoices data fetching error: ", err)
      );
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if (storedSearchBy) {
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getSupplierList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Supplier Name");
    setSearchKey("");
    setSearched(false);
  };

  useEffect(() => {
    getSupplierList();
    getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched == false) {
      getSupplierList();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  var rowDaata = [];
  for (let i = 0; i < supplierInvoicesList.length; i++) {
    rowDaata.push(
      createData(
        supplierInvoicesList[i].id,
        supplierInvoicesList[i].supplierName,
        supplierInvoicesList[i].invoiceNumber,
        supplierInvoicesList[i].invoiceDate
          ? moment(supplierInvoicesList[i].invoiceDate).format("DD-MM-YYYY")
          : "",
        supplierInvoicesList[i].invoiceAmount,
        supplierInvoicesList[i].invoiceFile,
        supplierInvoicesList[i].paymentStatus == 1 ? "Paid" : "Pending",
      )
    );
  }

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "5px 0px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            width: "100%",
          }}
          className="Listinputcss"
          value={searchBy}
          size="small"
          options={[
            { key: "supplierName", label: "Supplier Name" },
            { key: "invoiceNumber", label: "Invoice Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField
          style={{ width: "100%" }}
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: "off",
          }}
          className="Listinputcss"
          value={searchKey}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
        <Button
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/editsupplierInvoices/0"
          >
            <b>create supplier Invoices</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDaata?.length > 0 ? (
              rowDaata.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      const cellStyle =
                      column.id === "paymentStatus"
                        ? {
                            color: value === "Paid" ? "green" : "red",
                            fontWeight: "bold",
                          }
                        : {};
                        
                      return (
                        <TableCell key={column.id} align={column.align} style={cellStyle}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip
                          title="Edit Supplier Invoices"
                          placement="top-start"
                        >
                          <Button
                            component={Link}
                            to={`/editsupplierInvoices/${row.id}`}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="View Upload File" placement="top-start">
                          <Button
                            component="a"
                            href={row.invoiceFile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Delete Supplier Invoices"
                          placement="top-start"
                        >
                          <Button
                            disabled={
                              !(
                                editAccess?.includes(2) ||
                                editAccess?.includes(0)
                              )
                            }
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete Supplier Invoice?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon
                              style={{
                                color: !(
                                  editAccess?.includes(2) ||
                                  editAccess?.includes(0)
                                )
                                  ? "lightgray"
                                  : "#dc3545",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <h6>
                    <b>Data Not Found</b>
                  </h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </ListWrap>
  );
}
