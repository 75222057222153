import axios from "axios";
import { BASE_URL } from "../../global";
import { storeAxiosRequestData } from "../../offlineApp/OfflineDatabaseSetup";


// Create axios instance directly
const axiosWithToken = axios.create({
  baseURL: BASE_URL,
});

// Add an interceptor to include the token in the headers
axiosWithToken.interceptors.request.use(async(config) => {
  
    // if (['put', 'patch', 'post', 'delete'].includes(config.method.toLowerCase())) {
    //   const isDynamicFetchData = config.url.endsWith('dynamic') && config.headers?.requestType === 'fetchData';
      
    //   if (!isDynamicFetchData) {
    //     await storeAxiosRequestData(config.url, config.data, config.method);
    //   }
    // }
    
    const token = sessionStorage.getItem("authToken"); // Get the token from session storage
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`; // Include the token in the headers
    }
    return config;
});

// Export axiosWithToken directly
export default axiosWithToken;
