import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import CustomPDFViewer from "./CustomPDFViewer";

const extractYouTubeId = (url) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
  );
  return match ? match[1] : null;
};

export default function ResourceModel({ open, onClose, resourceUrl }) {
  const isYouTube =
    resourceUrl &&
    (resourceUrl?.includes("youtube.com/watch") ||
      resourceUrl?.includes("youtu.be/"));
  const isPDF = resourceUrl && resourceUrl?.endsWith(".pdf");
  const isImage =
    resourceUrl &&
    (resourceUrl?.endsWith(".jpg") ||
      resourceUrl?.endsWith(".jpeg") ||
      resourceUrl?.endsWith(".png"));

  const videoId = isYouTube ? extractYouTubeId(resourceUrl) : null;
  const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : "";
  let userName = localStorage.getItem("userName") || "Labcity";

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{
          position: "absolute",
          right: 25,
          top: 3,
          height: "25px",
          minWidth: "40px",
        }}
        onClick={onClose}
      >
        X
      </Button>
      <br />
      <DialogContent
        style={{ margin: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        {resourceUrl ? (
          isYouTube ? (
            <iframe
              title="Video"
              width="100%"
              height="450px"
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : isPDF ? (
            <WatermarkedContent userName={userName}>
            <CustomPDFViewer url={resourceUrl} />
            </WatermarkedContent>
          ) : isImage ? (
            <WatermarkedContent userName={userName}>
            <img
              src={resourceUrl}
              alt="Resource"
              style={{ width: "100%", height: "100%" }}
                onContextMenu={(e) => e.preventDefault()}
            />
            </WatermarkedContent>
          ) : (
            <Typography variant="h6">Unsupported resource type</Typography>
          )
        ) : (
          <Typography variant="h6">No resource URL provided</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

const WatermarkedContent = ({children, userName}) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      {children}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#D2CDCF",
          fontSize: "4rem",
          fontFamily: "Arial",
          fontWeight: "bold",
          textAlign: "center",
          zIndex: 999,
          transform: "rotate(336deg)",
        }}
      >
        {userName}
      </div>
    </div>
  );
};
