import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
  Box,
} from "@mui/material";
import { createUnitGuide, editUnitGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import EditWrap from "../../commonComponent/editWrap";

export default function UpdateUnit(props) {
  const { id } = useParams();
  const isEditing = id !== "0";
  const [disciplineId, setDisciplineId] = useState("");
  const [symbol, setSymbol] = useState("");
  const [disciplinesArray, setDisciplinesArray] = useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [symbolList, setSymbolList] = useState([]);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const getUnitList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        `${url}xjoin?_join=units.unit,_j,disc.discipline&_on1=(units.disciplineId,eq,disc.id)&_fields=units.symbol,disc.name,units.id,disc.id&_where=(units.id,eq,${id})`
      )
      .then((res) => {
        let resData = res.data[0];
        setSymbol(resData?.units_symbol);
        setDisciplineId(resData?.disc_id + ", " + resData?.disc_name);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getUnitList" ,"Edit Unit")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getsymbolList = () => {
    axiosWithToken
      .get(`https://labcity.bfiinfotech.co.in/api/predefineSymbols`)
      .then((res) =>setSymbolList(res.data));
  };

  const getDisciplineList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setDisciplinesArray(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getDisciplineList" ,"Edit Unit")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  useEffect(() => {
    getDisciplineList();
    getsymbolList();
    if (isEditing) {
      getUnitList();
    }
  }, [isEditing]);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    const jsonData = {
      disciplineId: parseInt(disciplineId.split(",")[0]),
      symbol: symbol,
    };

    let url = BASE_URL;

    const request = isEditing
      ? axiosWithToken.patch(`${url}unit/${id}`, jsonData)
      : axiosWithToken.post(`${url}unit`, jsonData);

    request
      .then((res) => {
        props.setLoader(false);
        toast(isEditing ? "Updated Unit!" : "Unit created successfully!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        // props.setLoader(false);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"Edit Unit")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {isEditing ? "Edit Unit" : "Create New Unit"}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button onClick={() => (isEditing ? editUnitGuide().drive() : createUnitGuide().drive())}>
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="unit_edit_discipline"
            value={disciplineId || ""}
            options={disciplinesArray.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              setDisciplineId(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
        <Autocomplete
            size="small"
            id="unit_edit_symbol"
            value={symbolList?.find((s) => s.symbol === symbol) || null}
            options={symbolList}
            getOptionLabel={(option) => `${option.name} (${option.symbol})`}
            renderInput={(params) => (
              <TextField {...params} label="Symbol *" />
            )}
            onChange={(event, newValue) => {
              setSymbol(newValue ? newValue.symbol : "");
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <span
                  style={{
                    color: option?.type == "custom"
                        ? "blue" : "black"
                  }}
                >
                 {option.name} ({option.symbol})
                </span>
              </li>
            )}
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          id="unit_edit_save"
          variant="contained"
          size="small"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          sx={{ m: 0 }}
          onClick={() => {
              if (!disciplineId) {
                toast.error('Please select a Discipline');
                return;
              }
              if (!symbol) {
                toast.error('Please select a Symbol');
                return;
              }
            props.setLoader(true);
            onSubmit();
          }}
        >
          {isEditing ? "Update" : "Save"}
        </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </EditWrap>
  );
}
