import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import ResourceModel from "../trainingResources/resourceModel";

export default function ViewUploadedTrainingFile({ certificateId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  // Resource Modal state
  const [openModalResource, setOpenModalResource] = React.useState(false);
  const [modalResourceUrl, setModalResourceUrl] = React.useState(""); 
  const handleOpenModalResource = (url) => {
    setModalResourceUrl(url);
    setOpenModalResource(true);
  }; 
  const handleCloseModalResource = () => {
    setOpenModalResource(false);
    setModalResourceUrl("");
  };

  const handleToggle = () => {
    if (!open) {
      getStandards();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function getStandards() {
    axiosWithToken
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${certificateId})`
      )
      .then((response) => {
        if (response?.data) {
          let standardIds = response?.data
            ?.map((e) => e.standardRanges)
            .filter(Boolean);
          const selectedStandardIds = [];
          standardIds.forEach((str) => {
            str.split(",").forEach((sub) => {
              const num = parseInt(sub.split(":")[0]);
              if (!selectedStandardIds.includes(num)) {
                selectedStandardIds.push(num);
              }
            });
          });

          if (selectedStandardIds) {
            let combineArray = [...new Set(selectedStandardIds)];
            axiosWithToken
              .get(
                BASE_URL + `standards?_where=(id,in,${combineArray.toString()})`
              )
              .then((res) => setStandardMasterArray(res.data))
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <ButtonGroup ref={anchorRef} aria-label="split button" size="small">
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {standardMasterArray?.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={() => {
                        if (option.certificateUrl) {
                          handleOpenModalResource(option.certificateUrl);
                        }
                      }}
                    >
                      {option?.standardName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      
      <ResourceModel
        open={openModalResource}
        onClose={handleCloseModalResource}
        resourceUrl={modalResourceUrl}
      />
    </div>
  );
}
