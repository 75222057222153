import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  TextField,
  Autocomplete,
  Box,
  useMediaQuery,
  Grid,
  Modal,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { disciplineListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "Sr. No" },
  { id: "name", label: "Discipline Name", minWidth: 40 },

  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(id, name, status) {
  return {
    id,
    name,
    status,
  };
}

export default function DisciplineList() {
  const disciplineListGuides = disciplineListGuide();
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [disciplines, setDiscList] = React.useState("");
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Discipline Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const _clientId = localStorage.getItem("clientId");
  const userType = localStorage.getItem("type");
  const [title, setTitle] = React.useState("");
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Discipline Name": "name",
        Status: "status",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }
    if (userType != "1" && userType != "2")
      whereConditions +=
        whereConditions.length > 0
          ? `and qt.clientId=${_clientId}`
          : `where qt.clientId=${_clientId}`;

    whereConditions +=
      whereConditions.length > 0 ? `and qt.status=1` : `where qt.status=1`;
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select * from discipline as qt ${whereConditions} order by qt.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = "select count(*) as count from discipline where status = 1";

    return data;
  };

  const getDList = (event) => {
    let data = [getQuery(), getQuery(true)];
    axiosWithToken
      .post(BASE_URL + `dynamic`, data[0])
      .then((res) => {
        setDiscList(res.data);
        setTotal(res.data.length);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getDList" ,"Discipline List")}><u>Error: {err.message}</u></h6>);
        }
      });
    // axiosWithToken
    //   .post(BASE_URL + `dynamic`, data[1])
    //   .then((res) => {
    //     setTotal(res.data[0].count);
    //   })
    //   .catch((err) => {
    //     console.log("clients data fetching error: ", err);
    //     toast.error(<h6 onClick={() => openErrorModal(err.message, "getDList" ,"Discipline List")}><u>Error: {err.message}</u></h6>);
    //   });
  };

  useEffect(() => {
    getDList();
  }, []);

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getDList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Discipline Name");
    setSearchKey("");
    setSearched(false); 
  };

  useEffect(() => {
    if (searched==false) {
    getDList();
    }
    if (searched) {
      setPage(0);
    }
  }, [total, rowsPerPage, page, searched]);

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `discipline/${id}`)
      .then((res) => {
        toast("Discipline was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log("err",error));

  };

  var refresh = () => {
    window.location.reload(false);
  };
  var rows = [];

  for (let i = 0; i < disciplines.length; i++) {
    rows.push(
      createData(disciplines[i].id, disciplines[i].name, disciplines[i].status)
    );
  }

  const filterSection = () => (
    <Grid
            container
            spacing={2}
            // justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "5px 0px" ,marginBottom:"2px"}}
          >
            <Grid item xs={12} sm={5} md={2} lg={2} id="discipline_list_searchby" >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          value={searchBy}
          options={[
            { key: "name", label: "Discipline Name" },          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
            <TextField
          id="discipline_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          className="Listinputcss"
          variant="outlined"
          value={searchKey}
          inputProps={{
            autoComplete: 'off',
          }}
          style={{ width: "100%" }}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
            <Button
        id="discipline_list_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
            </Grid>
          </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
      {filterModal()}
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar style={{padding:"0px"}}>
        <Button
        id="discipline_list_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/master/discipline/createDiscipline"
          onClick={() => {
            setTitle("Create Discipline");
          }}
        >
          <b>Create DISCIPLINE</b>
        </Button>
      </Toolbar>
      <Box sx={{display:"flex",gap:"0rem"}}>
      <Button
          style={{ display: isMobile ? "block" : "none" }}
          onClick={handleFilterOpen}
        >
          <FilterAltIcon />
        </Button>      
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          disciplineListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
      </Box>
    </div>
      {!isMobile && filterSection()}
    <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh", }}>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="discipline_list_table"
        sx={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.id == "id") {
                    value = index + 1;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Discipline" placement="top-start">
                      <Button
                       id="discipline_list_edit"
                        style={{
                          borderColor: "#dc3545",
                        }}
                        component={Link}
                        to={`/master/discipline/editDiscipline/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Descipline" placement="top-start">
                      <Button
                      id="discipline_list_delete"
                      disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                        onClick={(e) => {
                          if (
                            window.confirm("Really want to delete descipline?")
                          ) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length + 1} align="center">
             <h6><b>Data Not Found</b></h6>
            </TableCell>
          </TableRow>
        )} 
        </TableBody>
      </Table>
    </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </ListWrap>
  );
}
