var metric, imperial;

metric = {
  kgmc: {
    name: {
      singular: "Kilogram per Cubic Meter",
      plural: "Kilograms per Cubic Meter",
    },
    to_anchor: 1,
  },
  gmc: {
    name: {
      singular: "Gram per Cubic Meter",
      plural: "Grams per Cubic Meter",
    },
    to_anchor: 1 / 1000,
  },
  "g/ml": {
    name: {
      singular: "Gram per Milliliter",
      plural: "Grams per Milliliter",
    },
    to_anchor: 1000,
  },
  "kg/m³": {
    name: {
      singular: "Kilogram per Cubic Meter",
      plural: "Kilograms per Cubic Meter",
    },
    to_anchor: 1,
  },
  "g/cm³": {
    name: {
      singular: "Gram per Cubic Centimeter",
      plural: "Grams per Cubic Centimeter",
    },
    to_anchor: 1000,
  },
  "g/cm⁴": {
    name: {
      singular: "Gram per Cubic Centimeter",
      plural: "Grams per Cubic Centimeter",
    },
    to_anchor: 1000,
  },

  mgml: {
    name: {
      singular: "Milligram per Milliliter",
      plural: "Milligrams per Milliliter",
    },
    to_anchor: 1,
  },
};

imperial = {
  lbft3: {
    name: {
      singular: "Pound per Cubic Foot",
      plural: "Pounds per Cubic Foot",
    },
    to_anchor: 1,
  },
  lbgal: {
    name: {
      singular: "Pound per Gallon",
      plural: "Pounds per Gallon",
    },
    to_anchor: 1 / 8.34540445, // 1 gallon ≈ 8.34540445 pounds
  },
  ozgal: {
    name: {
      singular: "Ounce per Gallon",
      plural: "Ounces per Gallon",
    },
    to_anchor: 1 / 133.526, // 1 gallon ≈ 133.526 ounces
  },
};

module.exports = {
  metricDensity: metric,
  imperialDensity: imperial,
};
