import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import EditWrap from "../../commonComponent/editWrap";

export default function EditAssignedDuc(props) {
  const { id } = useParams();
  const isEditing = id !== "0";
  const [instrumentIds, setInstrumentIds] = useState([]);
  const [engineerID, setEngineerID] = useState("");
  const [instrumentList, setInstrumentList] = useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [engineerList, setEngineerList] = useState([]);
  const [assignedEngineerIds, setAssignedEngineerIds] = useState([]);
  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };

  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const getAllAssignedDucs = () => {
    let query = {
      query : "select * from assignedDucs where status = 1"
    }
    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        const assignedIds = res.data.map((item) => item.userId.toString());
        setAssignedEngineerIds(assignedIds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getassignedDucs = () => {
    axiosWithToken
      .get(BASE_URL + `assignedDucs/${id}`)
      .then((res) => {
        let resData = res.data[0];
        setEngineerID(resData?.userId.toString()); // Ensure ID is a string
        setInstrumentIds(
          resData?.instrumentIds ? resData.instrumentIds.split(",") : []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEngineerList = () => {
    axiosWithToken
      .get(BASE_URL + "users?_where=(type,eq,2)")
      .then((res) => {
        const engineers = res.data;
        const filteredEngineers = isEditing
          ? engineers
          : engineers.filter(
              (engineer) => !assignedEngineerIds.includes(engineer.id.toString())
            );
        setEngineerList(filteredEngineers);
      })
      .catch((err) => console.log(err));
  };

  const getInstrumentList = () => {
    axiosWithToken
      .get(BASE_URL + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInstrumentList();
    getAllAssignedDucs(); // Fetch all assigned Ducs to filter engineers
    getEngineerList();
    if (isEditing) {
      getassignedDucs();
    }
  }, [isEditing]);

  useEffect(() => {
    // Refresh engineer list after fetching all assigned Ducs
   if (assignedEngineerIds.length > 0){
     getEngineerList();
   }
  }, [assignedEngineerIds]);

  const refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    const jsonData = {
      instrumentIds: instrumentIds.join(","),
      userId: engineerID,
    };

    const request = isEditing
      ? axiosWithToken.patch(BASE_URL + `assignedDucs/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `assignedDucs`, jsonData);

    request
      .then((res) => {
        props.setLoader(false);
        toast("Assigned successfully!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {isEditing ? "Assigned Duc" : "Assigned Duc"}
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Autocomplete
            multiple
            size="small"
            value={instrumentList.filter((instrument) =>
              instrumentIds.includes(instrument.id.toString())
            )}
            options={instrumentList}
            getOptionLabel={(option) =>
              `${option.id}, ${option.instrumentName}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Instruments *" />
            )}
            onChange={(event, newValue) => {
              setInstrumentIds(
                newValue.map((instrument) => instrument.id.toString())
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Autocomplete
            size="small"
            options={engineerList}
            getOptionLabel={(option) => option.userName}
            renderInput={(params) => (
              <TextField {...params} label="Engineer Name *" />
            )}
            disabled={isEditing}
            onChange={(event, newValue) => {
              setEngineerID(newValue ? newValue.id.toString() : "");
            }}
            value={
              engineerList.find((engineer) => engineer.id.toString() === engineerID) || null
            }
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip
          title={
            !(editAccess?.includes(1) || editAccess?.includes(0))
              ? "You don't have access"
              : ""
          }
        >
          <Button
            variant="contained"
            size="small"
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            sx={{ m: 0 }}
            onClick={() => {
              if (instrumentIds.length === 0) {
                toast.error("Please select at least one instrument");
                return;
              }
              if (!engineerID) {
                toast.error("Please select an engineer");
                return;
              }
              props.setLoader(true);
              onSubmit();
            }}
          >
            {isEditing ? "Update" : "Save"}
          </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </EditWrap>
  );
}
