import { Box, Button, CircularProgress, Modal } from "@mui/material";
import Logo from "../../components/image/bfiloader.gif";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
const DownloadPdfLoaderModal = ({ loading, name, network, abort }) => {
  return (
    <Modal open={loading}>
      <Box
        style={{
          width: "100%",
          height: "100vh",
          border: "2px solid cadetblue",
          padding: "30px",
          textAlign: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div style={{background:"#fff",padding:"2rem 4rem", borderRadius:"20px", display:"grid"}}>
          <img
            src={Logo}
            alt="logo"
            style={{ height: "150px", borderRadius: "5%" }}
          />

{abort && (
                <Button
                variant="contained"
                style={{ backgroundColor: "grey" }}
                size="small"
                onClick={() => {
                  if (window.confirm("Are you sure you want to Abort Downloading?")) {
                    window.history.back();
                  }
                }}
              >
                Abort Downloading
              </Button>
          )}
          
          {network && (
            <>
              <h6
                style={{
                  color: "#C40C0C",
                  fontWeight: "900",
                  fontSize: "1.3rem",
                  textTransform: "capitalize",
                  fontFamily: "Poppins, sans-serif",
                  marginTop:"5px"
                }}
              >
                  please don't close tab.
              </h6>
              <br />
              <span
                style={{
                  color: "#000",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  textTransform: "capitalize",
                }}
              >
                please wait, or change the network connection. <WarningIcon style={{color:"#FF9A00",marginBottom:"5px"}}/>
                 <br />
                  Slow network connection.
                </span>
            </>
          )}

        </div>
      </Box>
    </Modal>
  );
};

export default DownloadPdfLoaderModal;
