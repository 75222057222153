import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

// import { ClassicTable } from "/../utils/components/Styles";
import { BASE_URL } from "./../../global";
// import { insert, view } from "/../components/common/api";
import { integerPropType } from "@mui/utils";
import { useNavigate} from "react-router-dom";
import Table from "react-bootstrap/Table";
import axiosWithToken from "../../utils/components/axiosTokenConfig";




export default function TodaysStdInOut() {
  const [standardOut, setStandardOut] = React.useState([]);
  const [standardIn, setStandardIn] = React.useState([]);
  const [overDue, setOverDue] = React.useState([]);
  const navigate = useNavigate();

  const handleClick = (type) => {
    let ids = [];
    switch (type) {
      case 'standardOut':
        ids = standardOut.map(item => item.standardId);
        break;
      case 'standardIn':
        ids = standardIn.map(item => item.id);
        break;
      case 'overDue':
        ids = overDue.map(item => item.standardId);
        break;
      default:
        return;
    }

    const queryString = `ids=[${ids.join(',')}]`;
    navigate(`/inOutReportList?${queryString}`);
  };

  const getStandardOut = (event) => {
    let sqlQuery={"query":`SELECT standardId, id, expectedReturnDate, returnDate FROM standardInOut WHERE outwardDate = CURRENT_DATE AND status = 1`}
    let url = BASE_URL;
    axiosWithToken
      .post(url + "dynamic",sqlQuery)
      .then((res) => {
          setStandardOut(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStandardIn = (event) => {
    let sqlQuery={"query":`SELECT id, stId, standardName, disciplineId FROM standards where lastModified = CURRENT_DATE`}

    let url = BASE_URL;
    axiosWithToken
    .post(url + "dynamic",sqlQuery)
      .then((res) => {
          setStandardIn(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getOverDue = (event) => {
    let sqlQuery={"query":`SELECT standardId, id, expectedReturnDate, returnDate FROM standardInOut WHERE expectedReturnDate = CURRENT_DATE AND status = 1 AND returnDate IS NULL`}
    let url = BASE_URL;
    axiosWithToken
    .post(url + "dynamic",sqlQuery)
      .then((res) => {
          setOverDue(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStandardOut();
    getStandardIn();
    getOverDue();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
     
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Master out </th>
            <th>Master In  </th>
            <th>Over-due Master </th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td  style={{ cursor: 'pointer' }} onClick={() => handleClick('standardOut')}>{standardOut.length}</td>
          <td  style={{ cursor: 'pointer' }} onClick={() => handleClick('standardIn')}>{standardIn.length}</td>
          <td  style={{ cursor: 'pointer' }} onClick={() => handleClick('overDue')}>{overDue.length}</td>
        </tr>
        </tbody>
      </Table>
    </Paper>
  );
}
