import React, { useRef } from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Tooltip } from "@mui/material";

const icons = ["±", "%", "!", "@", "&", "*","←","↑","→","∞","↓","∑","∂","≤","≥","√","Ω","°","°C","µm","√3","√2","√1/2","+","-","≠"];

const SymbolCopyModal = ({ onClose }) => {
  const textAreaRef = useRef(null);

  const handleIconClick = (icon) => {
    copyToClipboard(icon);
    onClose();
  };

  const copyToClipboard = (icon) => {
    if (!textAreaRef.current) return;
    textAreaRef.current.value = icon;
    textAreaRef.current.select();
    document.execCommand("copy");
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3>Copy an Icon</h3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {icons.map((icon, index) => (
            <Tooltip title={`Copy ${icon}`} key={index} placement="top">
              <IconButton
                style={{ fontSize: "1.5rem", margin: "5px" }}
                onClick={() => handleIconClick(icon)}
              >
                {icon}
              </IconButton>
            </Tooltip>
          ))}
        </div>
        <textarea
          ref={textAreaRef}
          style={{ position: "absolute", left: "-9999px", top: "0" }}
        />
      </div>
    </Modal>
  );
};

export default SymbolCopyModal;
