import React, { useEffect, useState } from "react";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import { toast } from "react-toastify";

import ResourceModel from "./resourceModel";
import ListWrap from "../commonComponent/listWrap";

export default function TrainingResources() {
  const [instrumentList, setInstrumentList] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [url, setUrl] = useState("");
  const [updatedInstruments, setUpdatedInstruments] = useState([]);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Modal state
  const [openModal, setOpenModal] = useState(false);
  const [modalVideoUrl, setModalVideoUrl] = useState("");

  // Handle modal open/close
  const handleOpenModal = (url) => {
    setModalVideoUrl(url);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalVideoUrl("");
  };

  const getInstrumentList = () => {
    axiosWithToken
      .get(BASE_URL + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        setInstrumentList(res.data);
        setUpdatedInstruments(
          res.data.filter((instrument) => instrument.videoUpload)
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getInstrumentList();
  }, []);

  const handleUpdateVideoUrl = () => {
    if (selectedInstrument) {
      const updatedUrl = url.trim() || null;

      axiosWithToken
        .patch(BASE_URL + `instruments/${selectedInstrument.id}`, {
          videoUpload: updatedUrl,
        })
        .then((res) => {
          toast.success("Resource updated successfully");
          window.location.reload();
        })
        .catch((err) => console.log("Update error:", err));
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate displayed rows
  const paginatedInstruments = updatedInstruments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <ListWrap>
      <Typography
        variant="h6"
        component="h6"
        style={{ textAlign: "left", margin: "1rem 0rem" }}
      >
        Training Resource
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            options={instrumentList}
            size="small"
            className="Listinputcss"
            getOptionLabel={(option) =>
              `${option.id}, ${option.instrumentName}` || ""
            }
            onChange={(event, newValue) => setSelectedInstrument(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Instrument"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            label="Video URL"
            className="Listinputcss"
            variant="outlined"
            size="small"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} textAlign={"left"}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleUpdateVideoUrl}
            disabled={!selectedInstrument}
          >
            Add Training Resources
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              width="100%"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Index</b>
                  </TableCell>
                  <TableCell>
                    <b>Instrument Name</b>
                  </TableCell>
                  <TableCell>
                    <b>View Resource</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInstruments.length > 0 ? (
                  paginatedInstruments.map((instrument, index) => (
                    <TableRow key={instrument.id}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>
                        {instrument.id},{instrument.instrumentName}
                      </TableCell>
                      <TableCell>
                        {instrument.videoUpload ? (
                          <YouTubeIcon
                            style={{ color: "#1976d2", cursor: "pointer" }}
                            onClick={() =>
                              handleOpenModal(instrument.videoUpload)
                            }
                          />
                        ) : (
                          "No URL"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography variant="body2" color="textSecondary">
                        <b>No Resources Available</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={updatedInstruments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <ResourceModel
        open={openModal}
        onClose={handleCloseModal}
        resourceUrl={modalVideoUrl}
      />
    </ListWrap>
  );
}
