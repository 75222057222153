//Followup

import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { showComponent } from "../helper/helpers";
import { quotationFollowUpGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const QuotationFollowup = () => {
  const quotationFollowUpGuides = quotationFollowUpGuide();
  const params = useParams();
  const [followUpData, setfollowUpData] = useState([]);
  const [srfObject, createSrfObject] = useState({
    quotationId: params.quotationId,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject({ ...newSrfObject });
  };

  const addFollowupData = () => {
    let url = BASE_URL;
    axiosWithToken
      .post(url + "quotationFollowup", srfObject)
      .then((res) => {
        getQuotationFollowUpData();
        setTimeout(refresh, 500);
        toast("Quotation Followup created successfully !");
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "addFollowupData" ,"Quotation Followup")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getQuotationFollowUpData = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url + `quotationFollowup?_where=(quotationId,eq,${params.quotationId})`
      )
      .then((res) => {
        setfollowUpData(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getQuotationFollowUpData" ,"Quotation Followup")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `quotationFollowup/${id}`)
      .then((res) => {
        getQuotationFollowUpData();
        toast("QuotationFollowup was deleted!");
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"Quotation Followup")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  useEffect(() => {
    getQuotationFollowUpData();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              quotationFollowUpGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="quotation_followUp_qtid"
            label="Quotation Id *"
            size="small"
            fullWidth
            variant="outlined"
            value={srfObject.quotationId || ""}
            onChange={(e) => {
              updateSrfObject("quotationId", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_followUp_remark"
            label="Remark *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("remark", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3} id="quotation_followUp_estimateClosureDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="EstimateClosure Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfObject.estimateClosureDate
                  ? new Date(srfObject.estimateClosureDate)
                  : ""
              }
              onChange={(newValue) => {
                updateSrfObject(
                  "estimateClosureDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_followUp_conversionRatio"
            label="Conversion Ratio *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("conversionRatio", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <Toolbar>
          <Button
            id="quotation_followUp_addbtn"
            variant="contained"
            size="small"
            onClick={() => addFollowupData()}
          >
            <b>ADD</b>
          </Button>
        </Toolbar>
      </Box>
      <Paper>
        <TableContainer component={Paper}>
          <Table id="quotation_followUp_table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Sr.No</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Estimate Closure Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Conversion Ratio
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Last Modified</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? followUpData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : followUpData
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.remark}</TableCell>
                  <TableCell>
                    {moment(row.estimateClosureDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>{row.conversionRatio}</TableCell>
                  <TableCell>
                    {moment(row.lastModified).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      {showComponent("delete") && (
                        <Tooltip title="Delete Client" placement="top-start">
                          <Button
                            id="quotation_followUp_table_deltebtn"
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete quotation?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            component="div"
            count={followUpData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </div>
  );
};

export default QuotationFollowup;
