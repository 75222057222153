var metric, imperial;

metric = {
  N: {
    name: {
      singular: "Newton",
      plural: "Newtons"
    },
    to_anchor: 1
  },
  kN: {
    name: {
      singular: "Kilonewton",
      plural: "Kilonewtons"
    },
    to_anchor: 1000
  },
  Kgf: {
    name: {
      singular: "Kilogram-force",
      plural: "Kilograms-force"
    },
    to_anchor: 9.80665
  }
};

imperial = {
  lbf: {
    name: {
      singular: "Pound-force",
      plural: "Pounds-force"
    },
    to_anchor: 1
  }
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "N",
      ratio: 0.224809
    },
    imperial: {
      unit: "lbf",
      ratio: 1 / 0.224809
    }
  }
};
