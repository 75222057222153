import * as React from "react";
import { BASE_URL, DISABLED_MODULE } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./user.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Box,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect } from "react";
import { useParams } from "react-router";
import { showComponent } from "../../helper/helpers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useGetRoleInfo } from "../../login/Login";
import { createUserGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { allSettings } from "../../../utils/components/allSettings";
import { getDiscipline } from "../standard/fetchHandler";
import EditWrap from "../../commonComponent/editWrap";
import { headers, rolesArray, userTypes , userDesignation, moduleList, editAccessOptions, moduleHierarchy } from "../../../constants/masterUserConstants";
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const EnabledModules = moduleList?.filter(
  (module) => !DISABLED_MODULE?.includes(module.name)
);


const filter = createFilterOptions();

const FileUploadComponent = ({ fileUploadResponse, setFileUploadResponse, label }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setFileUploadResponse(res.data);
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} style={{ margin: "5px 0px" }}>
      {fileUploadResponse && (
        <b>
        <a
        href={fileUploadResponse}
        target="_blank"
        rel="noopener noreferrer"
      >
        View File
      </a>
        </b>
      )}
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        size="small"
        style={{ float: "left" }}
      >
        {fileUploadResponse == null
          ? label
          : fileUploadResponse.split("_").pop()}
        <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
      </Button>
    </Grid>
  );
};

export default function EditUserMaster() {
  const createusersGuides = createUserGuide();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("");
  const [designation, setDesignation] = React.useState([]);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [executing, setExecuting] = React.useState(false);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [companies, setCompanies] = React.useState(null);
  const [companiesD, setCompaniesD] = React.useState(null);
  const [clientArray, setClientArray] = React.useState(null);
  const [clientArrayD, setClientArrayD] = React.useState(null);
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [selectedBranches, setSelectedBranch] = React.useState([]);
  const [companyHierarchySettingArray, setCompanyHierarchySettingArray] =
    React.useState([]);
  const [companyHierarchyUsersArray, setCompanyHierarchyUsersArray] =
    React.useState([]);
  const [selectedCompanyHierarchy, setSelectedCompanyHierarchy] =
    React.useState({});
  const [companyHierarchyOptions, setCompanyHierarchyOptions] = React.useState([
    [],
    [],
  ]);
  const [hierarchyResultArray, setHierarchyResultArray] = React.useState([
    [],
    [],
  ]);

  const [roles, setRoles] = React.useState([]);
  const [uroles, setURoles] = React.useState([]);
  const [clientID, setclientID] = React.useState(null);
  const [filterRole, setFilterRole] = React.useState([]);
  // const rolesArray = useGetRoleInfo(true, true);
  const { id } = useParams();
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [fileUploadResponse, setFileUploadResponse] = React.useState(null);
  const [fileUploadTrainingResponse, setFileTrainingUploadResponse] = React.useState(null);
  const [isTrainrainingMasterCertificate, setIstrainrainingMasterCertificate] = React.useState(false);
  const [disciplines, setDisciplineArray] = React.useState([]);
  const [disciplinIDS, setDisciplineIDS] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState([]);
  const [editUserAccess, setEditUserAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [companySetting, setCompanySetting] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");

  const UndiabledRoles = userTypes.filter(userType => {
    return !(userType.id === 3 && DISABLED_MODULE?.includes("ClientsUser"));
  });

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const filterClients = (clients, uBranches) => {
    if (!uBranches) return clients;
    return (clients || []).filter((c) => uBranches.includes(c.branch));
  };

  const getClientIdList = (uBranches) => {
    let url = BASE_URL;

    let Query = {
      query: `SELECT c.*, GROUP_CONCAT(a.address SEPARATOR ' || ') AS concatenated_addresses FROM clients AS c LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(c.address, ',', ',')) > 0 WHERE c.status != -1 GROUP BY c.id`,
    };

    axiosWithToken
      .post(url + `dynamic`, Query)
      // .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(filterClients(res.data, uBranches));
        setClientArrayD(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "getClientIdList",
                  "EditUser Master"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getBranches = (setBranch) => {
    axiosWithToken
      .get(BASE_URL + "branch?_where=(status,eq,1)")
      .then((res) => {
        setBranch(res.data);
        setBranches(
          res.data.map((item) => {
            return {
              id: item.id,
              branchName: item.branchName,
            };
          })
        );
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getBranches", "EditUser Master")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const data = new FormData();
  //   data.append("fileToUpload", file);

  //   axiosWithToken
  //     .post(BASE_URL + `fileUpload/fileUpload.php`, data)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setFileUploadResponse(res.data);
  //         toast.success("File Uploaded Successfully!");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       if (err.message !== "request_aborted") {
  //         toast.error("Something Went Wrong!");
  //       }
  //     });
  // };

  const onSubmit = async () => {
    setExecuting(true);
    // console.log("type : ", type);
    var jsonData = {
      userName: userName,
      password: password,
      type: type?.id,
      usersign: fileUploadResponse || null,
      companyName: companyName || null,
      trainingCertificate : fileUploadTrainingResponse || null,
      branch:
        type?.id !== 1
          ? (selectedBranches || []).map((b) => b.id).join(",")
          : null,
      modules:
        type?.id !== 1
          ? (selectedModules || []).map((m) => m.id).join(",")
          : null,
      // roles: roles.map((e) => e.id).join(","),
      roles: roles?.join(","),
      clientId: clientID || null,

      disciplineID: (disciplinIDS || [])?.map((e) => e.id).join(",") || null,
      editAccess: editAccess.join(",") || "0",

      designation: designation?.map((e) => e.id).join(","),
      companyHierarchy:
        Object.values(selectedCompanyHierarchy).join(",") == "default,default" || Object.values(selectedCompanyHierarchy).join(",") == ","
          ? null
          : Object.values(selectedCompanyHierarchy).join(","),
    };


    const validationField = {
      3: [
        { condition: !jsonData.clientId, message: "Please select a client." },
        {
          condition: !jsonData.roles,
          message: "Please select at least one role.",
        },
        {
          condition: !jsonData.modules,
          message: "Please select at least one module.",
        },
      ],
      2: [
        {
          condition: !jsonData.roles,
          message: "Please select at least one role.",
        },
        {
          condition: !jsonData.designation,
          message: "Please select at least one designation.",
        },
        {
          condition: !jsonData.branch,
          message: "Please select at least one branch.",
        },
        {
          condition: !jsonData.modules,
          message: "Please select at least one module.",
        },
      ],
    };

    const validationsRoles = validationField[type?.id] || [];
    for (const validation of validationsRoles) {
      if (validation.condition) {
        toast.error(validation.message);
        setExecuting(false);
        return;
      }
    }

    let ret = id
      ? axiosWithToken.patch(BASE_URL + `users/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        if (jsonData.type == 2 && companies) {
          let rows = [
            ...companies.map((company) => {
              return {
                userId: id ? id : res.data.insertId,
                companyId: company[0].id,
                startDate: company[1]
                  ? moment(company[1]).format("YYYY-MM-DD")
                  : null,
                endDate: company[2]
                  ? moment(company[2]).format("YYYY-MM-DD")
                  : null,
                status: 1,
                id: company[3],
              };
            }),
          ];
          let post = [],
            patch = [],
            patch_id = [],
            tmp = null;
          let tmp_id = null;
          rows.map((ua) => {
            tmp = { ...ua };
            delete tmp.id;
            if (ua.id) {
              patch_id.push(ua.id);
              patch.push(tmp);
            } else {
              post.push(tmp);
            }
          });
          let url = BASE_URL;
          Promise.all([
            ...post.map((pst) =>
              axiosWithToken.post(url + "userClientAssign", pst)
            ),
            ...patch.map((ptch, i) =>
              axiosWithToken.patch(
                url + `userClientAssign/${patch_id[i]}`,
                ptch
              )
            ),
          ])
            .then((res2) => {
              toast.success("Updated Sucessfully!");
              refresh();
            })
            .catch((err) => {
              if (err.message !== "request_aborted") {
                toast.error(
                  <h6
                    onClick={() =>
                      openErrorModal(err.message, "onSubmit", "EditUser Master")
                    }
                  >
                    <u>Error: {err.message}</u>
                  </h6>
                );
              }
              // console.log("Companies error   : ", err);
              // setExecuting(false);
            });
        } else {
          toast.success("Updated Sucessfully!");
          refresh();
        }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "onSubmit", "EditUser Master")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initModules = () => {
    let filteredModules = moduleList?.filter((module) => {
      // Include modules not in any child or parent modules themselves
      if (!Object.values(moduleHierarchy).some(range => module.id >= range.min && module.id <= range.max)) {
        return true;
      }
  
      // Include child modules if their parent module is selected
      return selectedModules.some(selectedModule => {
        const range = moduleHierarchy[selectedModule.id];
        return range && module.id >= range.min && module.id <= range.max;
      });
    });
    setModules(filteredModules);
  };

  function fetchUserDetails() {
    axiosWithToken.get(BASE_URL + `users/${id}`).then((res) => {
      let companyHierarchyArray =
        res?.data[0]?.companyHierarchy?.split(",") || [];

        let AccessId = (res.data[0].editAccess)?.split(",")?.map(Number);


      setCompanyHierarchyUsersArray(companyHierarchyArray);
      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      setEditAccess(AccessId);
      setclientID(res.data[0]?.clientId);
      setCompanyName(res.data[0]?.companyName);
      setFileUploadResponse(res.data[0]?.usersign);
      setFileTrainingUploadResponse(res.data[0]?.trainingCertificate);
      res.data[0]?.disciplineID
      ? setDisciplineIDS(
        res.data[0].disciplineID
          ?.split(",")
          .map(
            (groupId) =>
              disciplines.find(
                (group) => group.id === parseInt(groupId, 10)
              ) || null
          )
          .filter((group) => group !== null)
      ) 
      : setDisciplineIDS([]);      
      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      setType(userTypes.filter((t) => t.id == res.data[0].type)[0]);
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules
              ?.split(",")
              .map(
                (groupId) =>
                  EnabledModules.find(
                    (group) => group.id === parseInt(groupId, 10)
                  ) || null
              )
              .filter((group) => group !== null)
          )
        : setSelectedModules([]);

      res.data[0]?.designation
        ? setDesignation(
            res.data[0]?.designation
              ?.split(",")
              .map((m) => userDesignation[m - 1] || [])
          )
        : setDesignation([]);

      // Split the roles string into an array of role IDs
      const roleIds = res?.data[0]?.roles?.split(",");
      setRoles(roleIds);
      getBranches((inpt) => {
        let tmp = {};
        inpt.map((e) => (tmp[e.id] = e));
        res.data[0].branch
          ? setSelectedBranch(
              res.data[0].branch?.split(",").map((b) => tmp[b]) || []
            )
          : setSelectedBranch([]);
        getClientIdList((res.data[0].branch || "").split(","));
      });
      axiosWithToken
        .get(BASE_URL + `userClientAssign?_where=(userId,eq,${id})})`)
        .then((res2) => {
          // console.log("client company mapping : ", res2.data);
          let tCompanies = [];
          setCompaniesD(res2.data);
          setUserLoaded(true);
        });
    });
  }

  const fetchClientSettingsData = async () => {
    try {
      const sqlQuery = {
        query: `SELECT keyName, value,companyHierarchy  FROM clientSettings WHERE clientId = ${clientID} `,
      };

      const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);

      const response = res.data;

      // Use map to extract companyHierarchy and filter out empty strings and null values
      const HierarchyArray = response
        .map((ele) => ele.companyHierarchy)
        .filter((hierarchy) => hierarchy !== null && hierarchy !== "");

      // Use Set to ensure uniqueness and then convert back to array
      const uniqueArray = [...new Set(HierarchyArray)];

      // Split each element by "," and create an array of arrays
      const resultArray = uniqueArray.reduce((acc, element) => {
        const splitValues = element.split(",");
        splitValues.forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(value);
        });
        return acc;
      }, []);

      setHierarchyResultArray(resultArray);
      // Remove duplicates within each inner array
      const uniqueData = resultArray.map((innerArray) => [
        ...new Set(innerArray),
      ]);
      setCompanyHierarchyOptions(uniqueData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyHierarchyChange = (label, value) => {
    setSelectedCompanyHierarchy((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  useEffect(() => {
    const fetchDisciplineData = async () => {
      const disciplineData = await getDiscipline();
      setDisciplineArray(disciplineData);
    };
    fetchDisciplineData();

    if (!id) getBranches(() => {});
    if (!id) getClientIdList();
  }, []);
    
  useEffect(() => {
    if (id) fetchUserDetails();
  }, [disciplines]);

  useEffect(async () => {
    const AllSettings = await allSettings();
    const companyNames = AllSettings.find(({ keyName }) => keyName === "CompanyList")?.value?.split(",") || [];
    setCompanySetting(companyNames);

    const trainandMaster = AllSettings.find((ele) => ele.keyName === "TrainingAndMasterCertificate")?.value === "true";
    setIstrainrainingMasterCertificate(trainandMaster);

    let companyHierarchySetting = AllSettings?.find(
      (ele) => ele.keyName === "companyHierarchy"
    )?.value;
    setCompanyHierarchySettingArray(
      companyHierarchySetting ? companyHierarchySetting?.split(",") : ""
    );
  }, []);

  useEffect(() => {
    // Set default values dynamically based on some logic
    if (companyHierarchySettingArray?.length > 0) {
      const dynamicDefaults = {};
      companyHierarchySettingArray?.forEach((labelName) => {
        dynamicDefaults[labelName] = "default";
      });
      setSelectedCompanyHierarchy(dynamicDefaults);
    }
  }, [companyHierarchySettingArray]);

  useEffect(() => {
    if (companyHierarchySettingArray?.length > 0) {
      // Creating the object using reduce
      const companyHierarchyObject = companyHierarchySettingArray?.reduce(
        (acc, key, index) => {
          acc[key] = companyHierarchyUsersArray[index];
          return acc;
        },
        {}
      );

      setSelectedCompanyHierarchy(companyHierarchyObject);
    }
  }, [companyHierarchyUsersArray, companyHierarchySettingArray]);

  const updateCompanyHierarchyOptions = () => {
    const selectedDivision = selectedCompanyHierarchy?.Division || "default";

    const allIndices = hierarchyResultArray[0]
      ?.map((item, index) => (item === selectedDivision ? index : null))
      .filter((index) => index !== null);

    const result = hierarchyResultArray.map((el, i) =>
      i === 0 ? el : el.filter((item, ind) => allIndices.includes(ind))
    );

    const uniqueData = result.map((innerArray) => [...new Set(innerArray)]);
    setCompanyHierarchyOptions(uniqueData);
  };

  useEffect(() => {
    updateCompanyHierarchyOptions();
  }, [selectedCompanyHierarchy?.Division]);

  useEffect(() => {
    if (clientID) fetchClientSettingsData();
  }, [clientID]);

  useEffect(() => {
    if (roles?.length > 0) {
      let roleArray = [];
      roles?.forEach((roleId) => {
        let roleObject = filterRole?.find((ele) => ele?.id == roleId);
        if (roleObject) {
          roleArray.push(roleObject);
        }
      });
      setURoles(roleArray);
    }
  }, [roles, filterRole, id]);

  useEffect(() => {
    let filterRole;
    if (type?.id == 3) {
      filterRole = rolesArray.filter(
        (role) => role.id == 3 || role.id == 4 || role.id == 5
      );
    } else {
      filterRole = rolesArray.filter((role) => role.id == 1 || role.id == 2);
    }
    //  else {
    //   filterRole = rolesArray;
    // }
    setFilterRole(filterRole);
  }, [type?.id]);

  useEffect(() => {
    if ((id ? userLoaded : true) && companiesD && clientArray) {
      let validCompanies = companiesD?.map((c1) => [
        clientArray?.filter((c) => c?.id == c1?.companyId)[0] || null,
        c1.startDate,
        c1.endDate,
        c1.id,
      ]);
      setCompanies([...validCompanies]);
    }
  }, [companiesD, userLoaded, clientArray]);

  useEffect(() => {
    initModules();
  }, [selectedModules]);

  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {id
            ? `Edit ${type?.label ? type?.label : ""} : ${userName}`
            : "Add User"}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createusersGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="create_users_username"
            inputProps={{
              autoComplete: 'off',
            }}
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="create_users_password"
            label="Password *"
            value={password}
            size="small"
            type={id ? "password" : "text"}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
          />
          <div>
            <p id="letter" className={passVal[0] ? "valid" : "invalid"}>
              A <b>lowercase</b> letter
            </p>
            <p id="capital" className={passVal[1] ? "valid" : "invalid"}>
              A <b>capital (uppercase)</b> letter
            </p>
            <p id="number" className={passVal[2] ? "valid" : "invalid"}>
              A <b>number</b>
            </p>
            <p id="lengthMin" className={passVal[3] ? "valid" : "invalid"}>
              Minimum <b>8 characters</b>
            </p>
            <p id="lengthMax" className={passVal[4] ? "valid" : "invalid"}>
              Maximum <b>32 characters</b>
            </p>
          </div>
        </Grid>
        <FileUploadComponent
         fileUploadResponse={fileUploadResponse}
          setFileUploadResponse={setFileUploadResponse}
          label="Upload Image"
        />
      </Grid>
      <Grid container spacing={2}>
        {/* {!id && ( */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="create_users_usertype"
            options={UndiabledRoles}
            value={type}
            renderInput={(params) => (
              <TextField {...params} label="User Type *" />
            )}
            onChange={(e, val) => {
              setType(val);
            }}
          />
        </Grid>
        {/* )} */}
        {type?.id == 3 && (
          <>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                size="small"
                options={clientArrayD}
                value={
                  clientArrayD?.filter((ele) => ele?.id == clientID)?.[0] ||
                  null
                }
                getOptionLabel={(option) =>
                  `${option.id}, ${option.companyName}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a client"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setclientID(value?.id);
                }}
              />
            </Grid>

            {companyHierarchySettingArray.length > 0 &&
              companyHierarchySettingArray?.map((labelName, index) => {
                return (
                  <Grid item xs={6} sm={6} md={4} lg={3}>
                    <Autocomplete
                      key={selectedCompanyHierarchy[labelName]}
                      value={selectedCompanyHierarchy[labelName]}
                      onChange={(event, newValue) => {
                        handleCompanyHierarchyChange(labelName, newValue);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={
                        companyHierarchyOptions?.[index]?.includes("default")
                          ? companyHierarchyOptions?.[index]
                          : companyHierarchyOptions?.[index]?.concat(
                              "default"
                            ) || ["default"]
                      }
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // new  option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option}</li>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label={labelName} />
                      )}
                      size="small"
                    />
                  </Grid>
                );
              })}

          </>
        )}
        {type?.id != 5 && type?.id != 6 && (
          <Grid item xs={12} sm={6} md={3} lg={3} id="create_users_roles">
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={filterRole}
              defaultValue={undefined}
              value={uroles || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              renderInput={(params) => <TextField {...params} label="Roles" />}
              onChange={(event, value) => {
                // console.log("value",value)
                // setRoles([...(value?.map((e) => e.id) || [])]);
                const selectedRoleObjects = value.map((option) => option);
                  setURoles(selectedRoleObjects || []);
                  setRoles(selectedRoleObjects.map((option) => option.id) || []);
              }}
            />
          </Grid>
        )}

        {type?.id != 3 && (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              multiple
              id="action"
              size="small"
              options={
                type && type.id == 6
                  ? userDesignation.filter((d) => [9, 10, 11].includes(d.id))
                  : userDesignation || []
              }
              defaultValue={undefined}
              value={designation || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Designation"
                  placeholder="select user designation"
                />
              )}
              onChange={(event, value) => setDesignation(value || [])}
            />
          </Grid>
        )}

        {type?.id !== 1 && (
          <Grid item xs={12} sm={6} md={3} lg={3} id="create_users_branch">
            <Autocomplete
              multiple
              fullWidth
              id="branch"
              size="small"
              options={branches || []}
              defaultValue={undefined}
              value={selectedBranches || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.branchName || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.branchName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Branch"
                  placeholder="Select Branch"
                />
              )}
              onChange={(event, value) => {
                setClientArray(
                  filterClients(
                    clientArrayD,
                    value.map((b) => `${b.id}`)
                  )
                );
                setSelectedBranch(value || []);
              }}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid
            item
            // xs={id && type?.id === 2 ? 6 : 3}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            id="create_users_modules"
          >
            <Autocomplete
              multiple
              fullWidth
              id="modules"
              size="small"
              options={modules || []}
              defaultValue={undefined}
              value={selectedModules || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{ paddingLeft: option.id > 50 ? "1rem" : "0" }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select modules (optional)"
                  placeholder="select multiple modules"
                />
              )}
              onChange={(event, value) => {
                let updatedSelectedModules = [...value];            
              // Deselect child modules if parent module is deselected
              Object.entries(moduleHierarchy).forEach(([parentId, range]) => {
                if (!value.some((module) => module.id.toString() === parentId)) {
                  updatedSelectedModules = updatedSelectedModules.filter(
                    (module) => module.id < range.min || module.id > range.max
                  );
                }
              });
                setSelectedModules(updatedSelectedModules);
              }}
            />
          </Grid>
        )}
        {roles && roles.includes("2") && (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
          >
           <Autocomplete
            multiple
            fullWidth
              size="small"
              options={disciplines || []}
              defaultValue={undefined}
              getOptionLabel={(option) => `${option.id}. ${option.name}` || ""}
              value={disciplinIDS || []}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{ paddingLeft: option.id > 50 ? "1rem" : "0" }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                   {option.id}. {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Discipline"
                  placeholder="Select Discipline"
                />
              )}
              onChange={(e, value) => {
                setDisciplineIDS(value || []);
              }}
            />
          </Grid>
        )}
        {isTrainrainingMasterCertificate && (
          <FileUploadComponent
            fileUploadResponse={fileUploadTrainingResponse}
            setFileUploadResponse={setFileTrainingUploadResponse}
            label="Upload Training Certificate"
          />
        )}
     {type?.id !== 3 && (
        <>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              multiple
              size="small"
              options={editAccessOptions}
              getOptionLabel={(option) => option.label}
              value={editAccessOptions.filter((option) => editAccess?.includes(option.id))}
              renderInput={(params) => (
                <TextField {...params} label="Edit Access" />
              )}
              onChange={(event, newValue) => {
                setEditAccess(newValue.map((option) => option.id));
              }}
            />
          </Grid>
        </>
      )}

      {companySetting.length > 0 && (
      <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            options={companySetting || []}
            value={companyName}
            renderInput={(params) => (
              <TextField {...params} label="Select companyName" />
            )}
            onChange={(e, val) => {
              setCompanyName(val);
            }}
          />
        </Grid>
      )}
    
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: "16px" }}>
        {type?.id === 2 && (
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box sx={{ width: "100%" }} id="create_users_branchapply">
              <table className="user-client-table">
                <tr>
                  {headers.map((header, id) => (
                    <th key={`header-${id}`}>{header.name}</th>
                  ))}
                  {(companies || []).length > 1 && showComponent("delete") ? (
                    <th></th>
                  ) : (
                    ""
                  )}
                </tr>
                {(companies || []).map((e, idx) => {
                  const concatenatedAddresses =
                    e[0]?.concatenated_addresses || "";
                  const addressesArray = concatenatedAddresses.split(" || ");
                  return (
                    <tr key={idx}>
                      <td>
                        <Autocomplete
                          fullWidth
                          id="checkboxes-tags-demo"
                          size="small"
                          value={e[0] ? e[0] : {}}
                          options={clientArray || []}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.companyName || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Client"
                              // variant="standard"
                            />
                          )}
                          onChange={(event, value) => {
                            let tmp = [...companies];
                            tmp[idx][0] = { ...value };
                            setCompanies([...tmp]);
                          }}
                          // slotProps={{ textField: { variant: "standard" } }}
                        />
                      </td>
                      {/* <td>{e[0]?.concatenated_addresses || ""}</td> */}

                      <td>
                        {addressesArray.length === 1 ? (
                          addressesArray[0]
                        ) : (
                          <Autocomplete
                            fullWidth
                            id="checkboxes-tags-demo"
                            size="small"
                            options={addressesArray}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Addresses"
                                variant="outlined"
                              />
                            )}
                            // Additional Autocomplete props
                            // ...
                          />
                        )}
                      </td>

                      <td>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[1] ? new Date(e[1]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][1] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      <td
                        style={
                          !e[2] || new Date(e[2]) < new Date()
                            ? { backgroundColor: "#faa" }
                            : {}
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[2] ? new Date(e[2]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][2] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      {(companies || []).length > 1 &&
                      showComponent("delete") ? (
                        <td width={"15px"}>
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              let tmp = (companies || []).filter(
                                (_, i) => i !== idx
                              );
                              axiosWithToken
                                .delete(
                                  BASE_URL +
                                    `userClientAssign/${companies[idx][3]}`
                                )
                                .then((res) =>
                                  toast.success("deleted successfully!")
                                )
                                .catch((err) => {
                                  console.log("err : ", err);
                                  if (err.message !== "request_aborted") {
                                    toast.error(
                                      <h6
                                        onClick={() =>
                                          openErrorModal(
                                            err.message,
                                            "DeleteIcon",
                                            "EditUser Master"
                                          )
                                        }
                                      >
                                        <u>Error: {err.message}</u>
                                      </h6>
                                    );
                                  }
                                });
                              setCompanies(tmp);
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan={
                      (companies || []).length > 1
                        ? headers.length + 1
                        : headers.length
                    }
                  >
                    <Button
                      onClick={(e) => {
                        setCompanies([...(companies || []), [[], "", ""]]);
                      }}
                    >
                      <b style={{ fontSize: "18px" }}>ADD CLIENT</b>
                      <AddIcon />
                    </Button>
                  </td>
                </tr>
              </table>
            </Box>
          </Grid>
        )}
      </Grid>
      <br />

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="create_users_savebtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={
            !(editUserAccess == 1 || editUserAccess == 0) || // First condition
            executing || // Second condition starts here
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
      {/* <ToastContainer /> */}
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </EditWrap>
  );
}
